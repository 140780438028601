import request from "@/request";

export function getProductDetail(id:number){
    return request({
        url: '/user/product/product/' + id,
        method: 'get'
    })
}

export function getProductList(){
    return request({
        url: '/user/product/product',
        method: 'get'
    })
}