import request from "@/request";

export function getAdList(params:any){
    return request({
        url: '/portal/sale/ad',
        method: 'get',
        params: params
    })
}

export function getAdDetail(id:number){
    return request({
        url: '/portal/sale/ad/' + id,
        method: 'get',
    })
}