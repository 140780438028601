import request from "@/request";

export function userOrder(params:any){
    return request({
        url: '/user/order/do',
        method: 'post',
        data: params
    })
}

export function pay(){

}

export function getOrderList(params:any){
    return request({
        url: '/user/order/order',
        method: 'get',
        params: params
    })
}

export function getOrderDetail(id:number){
    return request({
        url: '/user/order/order/' + id,
        method: 'get'
    })
}

export function updateUserOrder(params:any){
    return request({
        url: '/user/order/order-info',
        method: 'post',
        data: params
    })
}

export function userPay(id:number, params:any){
    return request({
        url: '/user/order/pay/'+id,
        method: 'get',
        params: params
    })
}

export function getFinishOrderList(params:any){
    return request({
        url: '/user/order/finish-order',
        method: 'get'
    })
}