import request from "@/request";

export function getOrderList(params:any){
    return request({
        url: '/doctor/order/order',
        method: 'get',
        params: params
    })
}

export function getOrderDetail(id:number){
    return request({
        url: '/doctor/order/order/' + id,
        method: 'get'
    })
}

export function getNewOrderList(params:any){
    return request({
        url: '/doctor/order/newOrder',
        method: 'get'
    })
}