import router from "@/router";
import axios from "axios";

const request = axios.create({
    //baseURL: 'http://localhost:9509',
    baseURL: '/api',
    // 超时
    timeout: 10000,
    headers: {'Content-Type': 'application/json;charset=utf-8'}
})

request.interceptors.request.use((config) => {
    config.headers = config.headers || {}
    if(localStorage.getItem('token')){
        config.headers.token = localStorage.getItem('token') || ''
    }
    return config
},(error) => {
    return Promise.reject(error);
})

request.interceptors.response.use((config) => {
    console.log('响应拦截')
    if(config.data.code == 401){
        localStorage.removeItem('token')
        localStorage.removeItem('account_type')
        localStorage.removeItem('account_info')
        // router.go(0)
        router.push({name: 'home'})
    }
    console.log()
    return config
},(error) => {
    return Promise.reject(error);
})

export default request