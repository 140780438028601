import request from "@/request";

export function getDoctorList(params:any){
    return request({
        url: '/portal/doctor/doctor',
        method: 'get',
        params: params
    })
}

export function getDoctorDetail(id:number){
    return request({
        url: '/portal/doctor/doctor/' + id,
        method: 'get'
    })
}