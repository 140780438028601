import request from "@/request";

export function doctorLogin(params:any){
    return request({
        url: '/doctor/auth/login',
        method: 'post',
        data: params
    })
}

export function getDoctorInfo(){
    return request({
        url: '/doctor/auth/account-info',
        method: 'get'
    })
}

export function doctorRegister(params:any){
    return request({
        url: '/doctor/auth/register',
        method: 'post',
        data: params
    })
}

export function doctorUpdatePassword(params:any){
    return request({
        url: '/doctor/auth/update-password',
        method: 'put',
        data: params
    })
}

export function doctorSendCode(params:any){
    return request({
        url: '/doctor/auth/send-code',
        method: 'post',
        data: params
    })
}

export function getDoctorDetail(id:number){
    return request({
        url: '/doctor/doctor/doctor/'+id,
        method: 'get'
    })
}

export function editDoctor(params:any){
    return request({
        url: '/doctor/doctor/doctor',
        method: 'put',
        data: params
    })
}