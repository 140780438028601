import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '名医工作室 - 首页',
      description: '名医工作室是一家专注于医疗领域的综合性咨询平台，致力于为用户提供个性化、高品质的医疗服务、健康管理方案以及权威的学术与科普信息。',
      keyword: '名医工作室、肺结节、肺癌、呼吸重症康复、呼吸内科、何建行、钟南山、梁文华、郑则广、健嘉医疗'
    },
    component: HomeView
  },
  {
    path: '/pay',
    name: 'pay',
    meta: {
      title: '名医工作室 - 首页',
      description: '名医工作室是一家专注于医疗领域的综合性咨询平台，致力于为用户提供个性化、高品质的医疗服务、健康管理方案以及权威的学术与科普信息。',
      keyword: '名医工作室、肺结节、肺癌、呼吸重症康复、呼吸内科、何建行、钟南山、梁文华、郑则广、健嘉医疗'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PayView.vue')
  },
  {
    path: '/doctor-list',
    name: 'doctor-list',
    meta: {
      title: '名医工作室 - 医生列表',
      description: '名医工作室是一家专注于医疗领域的综合性咨询平台，致力于为用户提供个性化、高品质的医疗服务、健康管理方案以及权威的学术与科普信息。',
      keyword: '名医工作室、肺结节、肺癌、呼吸重症康复、呼吸内科、何建行、钟南山、梁文华、郑则广、健嘉医疗'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/DoctorListView.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: '名医工作室 - 在线科普',
      description: '名医工作室是一家专注于医疗领域的综合性咨询平台，致力于为用户提供个性化、高品质的医疗服务、健康管理方案以及权威的学术与科普信息。',
      keyword: '名医工作室、肺结节、肺癌、呼吸重症康复、呼吸内科、何建行、钟南山、梁文华、郑则广、健嘉医疗'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  {
    path: '/news-member',
    name: 'news-member',
    meta: {
      title: '名医工作室 - 学术组织',
      description: '名医工作室是一家专注于医疗领域的综合性咨询平台，致力于为用户提供个性化、高品质的医疗服务、健康管理方案以及权威的学术与科普信息。',
      keyword: '名医工作室、肺结节、肺癌、呼吸重症康复、呼吸内科、何建行、钟南山、梁文华、郑则广、健嘉医疗'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsMemberView.vue')
  },
  {
    path: '/news-detail',
    name: 'news-detail',
    meta: {
      title: '名医工作室 - 详情',
      description: '名医工作室是一家专注于医疗领域的综合性咨询平台，致力于为用户提供个性化、高品质的医疗服务、健康管理方案以及权威的学术与科普信息。',
      keyword: '名医工作室、肺结节、肺癌、呼吸重症康复、呼吸内科、何建行、钟南山、梁文华、郑则广、健嘉医疗'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsDetailView.vue')
  },
  {
    path: '/subject-detail',
    name: 'subject-detail',
    meta: {
      title: '名医工作室 - 详情',
      description: '名医工作室是一家专注于医疗领域的综合性咨询平台，致力于为用户提供个性化、高品质的医疗服务、健康管理方案以及权威的学术与科普信息。',
      keyword: '名医工作室、肺结节、肺癌、呼吸重症康复、呼吸内科、何建行、钟南山、梁文华、郑则广、健嘉医疗'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SubjectDetailView.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '名医工作室 - 医生注册',
      description: '名医工作室是一家专注于医疗领域的综合性咨询平台，致力于为用户提供个性化、高品质的医疗服务、健康管理方案以及权威的学术与科普信息。',
      keyword: '名医工作室、肺结节、肺癌、呼吸重症康复、呼吸内科、何建行、钟南山、梁文华、郑则广、健嘉医疗'
    },
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/TestView.vue')
  },
  {
    path: '/create-order',
    name: 'create-order',
    component: () => import('../views/CreateOrderView.vue')
  },
  {
    path: '/print',
    name: 'print',
    component: () => import('../views/PrintView.vue')
  },
  {
    path: '/user',
    name: 'user',
    redirect: { name: 'user-info' },
    component: () => import('../views/UserView.vue'),
    children: [
      {
        name: 'user-info',
        path: 'info',
        component: () => import('@/views/User/UserInfoView.vue'),
      },
      // {
      //   name: 'user-casedata',
      //   path: 'casedata',
      //   component: () => import('@/views/User/CaseDataView.vue'),
      // },
      {
        name: 'user-orderlist',
        path: 'orderlist',
        component: () => import('@/views/User/OrderListView.vue'),
      },
      {
        name: 'user-orderdetail',
        path: 'orderdetail',
        component: () => import('@/views/User/OrderDetailView.vue'),
      },
    ]
  },
  {
    path: '/doctor',
    name: 'doctor',
    redirect: { name: 'doctor-index' },
    component: () => import('../views/DoctorView.vue'),
    children: [
      {
        name: 'doctor-index',
        path: 'index',
        component: () => import('@/views/Doctor/DoctorIndexView.vue'),
      },
      {
        name: 'doctor-orderlist',
        path: 'orderlist',
        component: () => import('@/views/Doctor/OrderListView.vue'),
      },
      // {
      //   name: 'doctor-casedata',
      //   path: 'casedata',
      //   component: () => import('@/views/Doctor/CaseDataView.vue'),
      // },
      {
        name: 'doctor-orderdetail',
        path: 'orderdetail',
        component: () => import('@/views/Doctor/OrderDetailView.vue'),
      },
      {
        name: 'doctor-info',
        path: 'info',
        component: () => import('@/views/Doctor/DoctorInfoView.vue'),
      },
    ]
  },
  {
    path: '/order',
    name: 'order',
    redirect: { name: 'order-askorder' },
    component: () => import('../views/OrderView.vue'),
    children: [
      {
        name: 'order-askorder',
        path: 'askorder',
        component: () => import('@/views/Order/AskOrderView.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    // 路由发生变化修改页面title
    if (to.meta.title) {
      document.title = to.meta.title + ''
      // document.querySelector('meta[name="description"]')?.setAttribute('content', 'aaaadescription')
      // document.querySelector('meta[name="keywords"]')?.setAttribute('content', 'aaaakeywords')
    }
    if(localStorage.getItem('account_type') == 'user'){
      if(to.path.substring(1,8) == 'doctor/'){
        router.push({name: "home"})
      }
    }else if(localStorage.getItem('account_type') == 'doctor'){
      if(to.path.substring(1,8) != 'doctor/'
        && to.path != '/register' 
        && to.path != '/news' 
        && to.path != '/news-detail'
        && to.path != '/news-member'){
        router.push({name: "doctor"})
      }
    }else{
      if(to.path.substring(1,8) == 'doctor/' || to.path.substring(1,5) == 'user/'){
        router.push({name: "home"})
      }
    }

    next()
  })

export default router
